/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mainWrapper, .mainWrapperLoggedOut {
  padding-top: env(safe-area-inset-top);
}

.icon-button-fix {
  padding-inline-start: 15px !important;
}

.cardInHeader {
  background: transparent !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}

.customSnackBar {
  margin-top: env(safe-area-inset-top) !important;
}

.highlight {
  background-color: yellow;
}

.walkthrough-btn {
  border-radius: 7px;
  background-color: #00aae5;
  color: white;
  padding: 10px 15px;

  &:hover {
    cursor: pointer;
  }
}

.walkthrough-panel {
  border-radius: 20px !important;
  padding: 10px !important;
}

.introjs-progressbar {
  background-color: #00aae5 !important;
}

.introjs-tooltipReferenceLayer {
  visibility: visible !important;
}

.inline-spinner {
  display: inline-block !important;
  height: 20px !important;
  width: 20px !important;
  margin-inline-end: 10px;

  &.inline-spinner-mobile {
    margin-inline-end: 25px;
    margin-inline-start: 10px;
  }
}

.top-scrollbar {
  overflow-x: auto;
  overflow-y: hidden; /* No vertical scrolling */
  width: 100%;
  height: 16px; /* Matches the height of the scrollbar */
  margin-top: 10px;
}

.scroll-dummy {
  height: 1px; /* Dummy div should be almost invisible */
}

.bottom-scrollbar {
  overflow-x: auto;
  overflow-y: hidden; /* Prevent vertical scrolling */
}


@media (max-width: 430px) {
  .invoiceSubmitModal {
    mat-dialog-container {
      min-height: 390px;
    }

    mat-card-subtitle {
      margin-top: 0px !important;
      margin-bottom: 25px !important;
    }

    button {
      height: 50px;
    }
  }
}

@media (max-width: 310px) {
  .invoiceSubmitModal {
    mat-dialog-container {
      min-height: 410px;
    }

    mat-card-subtitle {
      margin-top: 0px !important;
      margin-bottom: 25px !important;
    }

    button {
      height: 50px;
    }
  }
}