@use "@angular/material" as mat;
@include mat.core();

@import "variables";
@import "layouts/transitions";
@import "helpers/color";
@import "helpers/icon-size";

//Theme colors

@import "themecolors/default_theme";
@include mat.all-component-themes($orangetheme);

.dark-theme {
  @include mat.all-component-colors($darkorangetheme);
}

// .dark-theme .aqua_theme {
//   @include mat.all-component-colors($darkaquatheme);
// }

.blue_theme {
  @import "themecolors/blue_theme";
  @include mat.all-component-colors($bluetheme);
}

.aqua_theme {
  @import "themecolors/aqua_theme";
  @include mat.all-component-colors($aquatheme);
}

.purple_theme {
  @import "themecolors/purple_theme";
  @include mat.all-component-colors($purpletheme);
}

.green_theme {
  @import "themecolors/green_theme";
  @include mat.all-component-colors($greentheme);
}

.pink_theme {
  @import "themecolors/pink_theme";
  @include mat.all-component-colors($pinktheme);
}

//container layout
@import "overrides/materialoverrides";
@import "dark/dark";
@import "container";
@import "layouts/layouts";
@import "grid/grid";
@import "helpers/custom-flex";
@import "helpers/index";

// horizontal
@import "horizontal/horizontal";

// apps
@import "apps/calendar";
@import "apps/email";

// pages
@import "pages/dashboards";
@import "pages/auth";
@import "pages/common";

// RTL Theme
@import "rtl/rtl";


.m-b-30 {
  margin-bottom: 50px !important;
}

.m-b-60 {
  margin-bottom: 80px !important;
}

.m-b-130 {
  margin-bottom: 130px !important;
}

ion-segment {
  ion-segment-button {
      --indicator-color: #00aae5;
      --color-checked: #00aae5;
  }
}